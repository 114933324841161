<template>
  <v-dialog v-model="mostrar" max-width="500" persistent>
    <v-card>
      <v-toolbar color="primary" dark dense flat
        ><h3>Agregar sesión</h3>
        </v-toolbar
      >
      <v-container>
        <v-row>
          <v-col cols="12" style="padding-bottom: 1px">
            <v-text-field
              v-model="nombre"
              name="SG-nombre"
              label="Nombre"
              type="text"
              :loading="loading"
              :disabled="loading"
              color="blue-grey"
              outlined
              dense
              :error-messages="nombreErrors"
            >
            </v-text-field>
          </v-col>
          <v-col cols="6" style="padding-top: 1px; padding-bottom: 1px">
            <v-menu
              v-model="fechaMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  label="Fecha"
                  :value="fechaTexto"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  dense
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="fecha"
                locale="es"
                @input="fechaMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>

          <v-col cols="6" style="padding-top: 1px">
            <v-menu
              ref="menu"
              v-model="horaMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="hora"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="hora"
                  label="Hora"
                  prepend-inner-icon="mdi-clock-time-four-outline"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  dense
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="horaMenu"
                v-model="hora"
                format="24hr"
                full-width
                @click:minute="$refs.menu.save(hora)"
              ></v-time-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" style="padding-top: 1px">
            <v-select
              v-model="consejoSelect"
              :items="consejos"
              item-text="nombre"
              item-value="_id"
              outlined
              dense
            />
          </v-col>
        </v-row>
      </v-container>
      <v-divider class="mx-3"></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" small outlined @click="$emit('cancelar')"
          >Cancelar</v-btn
        >
        <v-btn
          color="primary"
          small
          :class="{ 'disable-events': blockAceptar }"
          @click="agregarSesion()"
          >Agregar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { DateTime } from "luxon";
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";

import { getConsejosService } from "../consejos/consejos.service";
import { agregarSesionService } from "./administrar.service";

export default {
  name: "agregarSesion",
  props: ["mostrar"],
  mixins: [validationMixin],
  computed: {
    ...mapGetters(["userAdmin"]),
    fechaTexto() {
      let fecha = DateTime.fromISO(this.fecha);
      return fecha.toLocaleString(DateTime.DATE_MED);
    },
    blockAceptar() {
      if (this.loading) return true;
      if (this.nombreErrors.length > 0) return true;
      if (this.nombre.length == 0) return true;
      return false;
    },
  },
  validations: {
    nombre: { required, minLength: minLength(5) },
  },
  data() {
    return {
      loading: false,
      nombre: "",
      fecha: "",
      fechaMenu: false,
      hora: "09:00",
      horaMenu: false,
      nombreErrors: [],
      consejos: [],
      consejoSelect: null,
    };
  },
  mounted() {
    this.fecha = DateTime.now().toISODate();
    this.cargarConsejos();
  },
  methods: {
    validarNombre() {
      this.$v.nombre.$touch();
      this.nombreErrors = [];
      !this.$v.nombre.required && this.nombreErrors.push("Campo requerido.");
      !this.$v.nombre.minLength &&
        this.nombreErrors.push("Mínimo 5 caracteres.");
    },
    async cargarConsejos() {
      try {
        this.loading = true;
        const serverResponse = await getConsejosService();
        this.loading = false;
        this.$validateResponse(serverResponse);
        if (!serverResponse.ok) {
          throw new Error(serverResponse.mensaje);
        } else {
          this.consejos = serverResponse.consejos;
          if (this.consejos.length > 0)
            this.consejoSelect = this.consejos[0]._id;
        }
      } catch (error) {
        this.loading = false;
        this.$notificarError(error);
      }
    },
    async agregarSesion() {
      try {
        this.loading = true;
        const sendData = {
          nombre: this.nombre,
          fecha: this.fecha,
          hora: this.hora,
          consejo: this.consejoSelect,
        };
        const serverResponse = await agregarSesionService(sendData);
        this.loading = false;
        this.$validateResponse(serverResponse);
        if (!serverResponse.ok) {
          this.$notificarError(serverResponse.mensaje);
        } else {
          const emitData = { sesion: serverResponse.sesion };
          this.$emit("sesionAgregada", emitData);
        }
      } catch (error) {
        this.loading = false;
        this.$notificarError(error);
      }
    },
  },
};
</script>
