<template>
  <v-card outlined>
    <v-list>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            <router-link :to="`/admin/sesion/${sesion._id}`" class="link">
              {{ sesion.nombre }}
            </router-link>
          </v-list-item-title>
          <v-list-item-subtitle>
            <v-chip
              v-if="!sesion.consejo"
              color="orange darken-1"
              outlined
              x-small
              >No se ha asignado consejo</v-chip
            >
          </v-list-item-subtitle>
          <v-list-item-subtitle>{{ fechaLocal }}</v-list-item-subtitle>
          <v-list-item-subtitle
            ><v-chip
              v-if="sesion.abierta"
              color="green darken-1"
              outlined
              x-small
              >Abierta</v-chip
            >
            <v-chip v-else color="red darken-1" outlined x-small
              >Cerrada</v-chip
            >
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { DateTime } from "luxon";

export default {
  name: "sesionItemComponent",
  props: ["sesion"],
  computed: {
    ...mapGetters(["userAdmin"]),
  },
  watch: {
    sesion(value){
      if(value) this.actualizarHora();
    }
  },
  data() {
    return {
      loading: false,
      fechaLocal: "",
    };
  },
  mounted() {
    this.actualizarHora();
  },
  methods: {
    actualizarHora() {
      let fecha = DateTime.fromISO(this.sesion.fecha);
      this.fechaLocal = fecha.toLocaleString(DateTime.DATETIME_MED);
    }
  }
};
</script>

<style>
.red-text {
  color: brown;
}
</style>