<template>
<div>
    <v-row v-if="loading">
      <v-col md="12" v-for="number in 3" :key="'loader-' + number">
        <v-skeleton-loader
          class="mx-auto"
          type="card"
          height="100"
        ></v-skeleton-loader>
      </v-col>
    </v-row>

    <v-row v-else>
   
      <v-col>
        <v-card flat>
          <v-toolbar dense flat>
            <v-spacer></v-spacer>
            <v-btn color="primary" small @click="agregarSesion = true"
              >Agregar sesión</v-btn
            >
          </v-toolbar>
        </v-card>
      </v-col>
     
         <v-container>
        <v-col cols="12" v-for="(sesion, index) in sesiones" :key="`sesion-${index}`">
          <sesionItemComponent :sesion="sesion" />
        </v-col>
      </v-container>


      <agregarSesionComponent
        v-if="agregarSesion"
        :mostrar="agregarSesion"
        @cancelar="agregarSesion = false"
        @sesionAgregada="sesionAgregada"
      />
    </v-row>
</div>
</template>

<script>
import { mapGetters } from "vuex";

import agregarSesionComponent from "./agregarSesion.vue";
import sesionItemComponent from "./sesionItem.vue";
import { getSesionesService } from "./administrar.service";

export default {
  name: "administrarComponent",
  components: { sesionItemComponent, agregarSesionComponent },
  computed: {
    ...mapGetters(["userAdmin"]),
  },
  data() {
    return {
      loading: false,
      agregarSesion: false,
      sesiones: [],
    };
  },
  mounted() {
    if (!this.userAdmin) this.$router.push("/sesiones");
    else this.cargarSesiones();
  },
  methods: {
    async cargarSesiones() {
      try {
        this.loading = true;
        const serverResponse = await getSesionesService();
        this.loading = false;
        this.$validateResponse(serverResponse);
        if (!serverResponse.ok) {
          this.$notificarError(serverResponse.mensaje);
        } else {
          this.sesiones = serverResponse.sesiones;
        }
      } catch (error) {
        this.loading = false;
        this.$notificarError(error);
      }
    },
    sesionAgregada(data) {
      this.sesiones.unshift(data.sesion);
      this.agregarSesion = false;
    },
  },
};
</script>